import { Tooltip } from '@/ui/components/Tooltip';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';
import { CarData } from '@/types';
import dayjs from 'dayjs';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';

interface CarCardProps {
  className?: string;
  data: CarData;
}

const CarCard: FC<CarCardProps> = ({ className, data }) => {
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, 'carInfoBlock'),
    [locale],
  );

  const availabilityYear = dayjs(
    `01-${data.Availability_Date_From}`,
    'DD-MM-YYYY',
  ).get('year');

  const fullCarName = `${data.Vehicle_Make} ${data.Vehicle_Model} ${
    data?.Vehicle_Model_Version ? data?.Vehicle_Model_Version : ''
  }`;

  const efficiency = `${(data.Efficiency_Real / 100).toFixed(3)} kWh/km`;

  const fastChargePowerMax = data.Fastcharge_Power_Max
    ? `${data.Fastcharge_Power_Max} kW`
    : 'n/a kW';
  const fastChargeSpeed = data.Fastcharge_ChargeSpeed
    ? `${data.Fastcharge_ChargeSpeed} km/hr`
    : 'n/a km/hr';

  const standartChargePower = `${data.Charge_Standard_Power} kW`;
  const standartChargeSpeed = `${data.Charge_Standard_ChargeSpeed} km/hr`;

  const rangeReal = `${data.Range_Real} km`;
  const useableCapacity = `${data.Battery_Capacity_Useable} kWh`;

  let fooRef = null;
  return (
    <Root className={className}>
      <Wrapper>
        <Head>
          <ImageWrap>
            <CardImage src={data.Images[0]} alt={fullCarName} />
          </ImageWrap>
          <div>
            <Title>
              {fullCarName} (from {availabilityYear})
            </Title>
            <CarVariant>
              {dataLocale.typeCar}
              <span></span>
              {useableCapacity}
            </CarVariant>
          </div>
        </Head>
        <TechicalInfo>
          <TechicalInfoSection>
            <TechicalInfoSectionHead>
              <TechicalInfoSectionHeadTitle>
                {dataLocale.range.title}
              </TechicalInfoSectionHeadTitle>
              <TechicalInfoSectionHeadTooltip
                data-tip={dataLocale.range.tooltipText}
              />
              <Tooltip effect="solid" />
            </TechicalInfoSectionHead>
            <TechicalInfoValues>
              <TechicalInfoValue>{rangeReal}</TechicalInfoValue>
            </TechicalInfoValues>
          </TechicalInfoSection>

          <TechicalInfoSection>
            <TechicalInfoSectionHead>
              <TechicalInfoSectionHeadTitle>
                {dataLocale.dcFastCharging.title}
              </TechicalInfoSectionHeadTitle>
              <TechicalInfoSectionHeadTooltip data-tip data-for="fast-charge" />
              <Tooltip id="fast-charge" effect="solid">
                {fastChargePowerMax} –
                {` ${dataLocale.dcFastCharging.tooltipPower}`} <br />
                {fastChargeSpeed} -
                {` ${dataLocale.dcFastCharging.tooltipChargeSpeed}`}
              </Tooltip>
            </TechicalInfoSectionHead>
            <TechicalInfoValues>
              <TechicalInfoValue>{fastChargePowerMax}</TechicalInfoValue>
              <TechicalInfoValue>{fastChargeSpeed}</TechicalInfoValue>
            </TechicalInfoValues>
          </TechicalInfoSection>

          <TechicalInfoSection>
            <TechicalInfoSectionHead>
              <TechicalInfoSectionHeadTitle>
                {dataLocale.efficiency.title}
              </TechicalInfoSectionHeadTitle>
              <TechicalInfoSectionHeadTooltip
                data-tip={dataLocale.efficiency.tooltipText}
              />
              <Tooltip effect="solid" />
            </TechicalInfoSectionHead>
            <TechicalInfoValues>
              <TechicalInfoValue>{efficiency}</TechicalInfoValue>
            </TechicalInfoValues>
          </TechicalInfoSection>
          <TechicalInfoSection>
            <TechicalInfoSectionHead>
              <TechicalInfoSectionHeadTitle>
                {dataLocale.acOnboardCharging.title}
              </TechicalInfoSectionHeadTitle>
              <TechicalInfoSectionHeadTooltip data-tip data-for="ac-onboard" />
              <Tooltip id="ac-onboard" effect="solid">
                {standartChargePower} –
                {` ${dataLocale.dcFastCharging.tooltipPower}`} <br />
                {standartChargeSpeed} -
                {` ${dataLocale.dcFastCharging.tooltipChargeSpeed}`}
              </Tooltip>
            </TechicalInfoSectionHead>
            <TechicalInfoValues>
              <TechicalInfoValue>{standartChargePower}</TechicalInfoValue>
              <TechicalInfoValue>{standartChargeSpeed}</TechicalInfoValue>
            </TechicalInfoValues>
          </TechicalInfoSection>
        </TechicalInfo>
      </Wrapper>
    </Root>
  );
};

const Root = styled.article`
  background: ${COLORS.lightGray4};
  border-radius: 20px;
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 40px 60px;
  @media (max-width: 1024px) {
    padding: 30px;
  }
  @media (max-width: 700px) {
    padding: 40px 20px;
  }
`;

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  @media (max-width: 700px) {
    margin-bottom: 32px;
  }
  @media (max-width: 650px) {
    display: block;
  }
`;

const ImageWrap = styled.div`
  margin-right: 23px;
  border-radius: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 185px;
  @media (max-width: 768px) {
    max-width: 150px;
  }
  @media (max-width: 700px) {
    margin-right: 16px;
  }
  @media (max-width: 650px) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 12px;
  }
`;

const CardImage = styled.img`
  height: 135px;
  object-fit: cover;
  @media (max-width: 768px) {
    height: auto;
  }
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black2};
  margin-bottom: 17px;

  @media (max-width: 650px) {
    margin-bottom: 12px;
  }
`;

const CarVariant = styled.p`
  display: flex;
  align-items: center;

  ${TYPOGRAPHY.headlineSemiBold22};
  color: ${COLORS.grayDarker2};
  span {
    display: inline-block;
    width: 2px;
    height: 30px;
    margin-right: 15px;
    margin-left: 15px;
    background-color: ${COLORS.lightGray7};
  }
  @media (max-width: 600px) {
    ${TYPOGRAPHY.body1SemiBold18};
    span {
      height: 24px;
      margin-right: 6px;
      margin-left: 6px;
    }
  }
`;

const TechicalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px 24px;
  @media (max-width: 700px) {
    display: block;
  }
`;

const TechicalInfoSection = styled.div`
  @media (max-width: 700px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const TechicalInfoSectionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const TechicalInfoSectionHeadTitle = styled.p`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black2};
`;

const TechicalInfoSectionHeadTooltip = styled.div`
  width: 20px;
  height: 20px;
  background: url('/images/subtract.svg') no-repeat center;
  background-size: cover;
  cursor: pointer;
`;

const TechicalInfoValues = styled.div`
  display: flex;
`;

const TechicalInfoValue = styled.p`
  flex-grow: 1;
  text-align: center;
  padding: 9px 10px;
  background-color: ${COLORS.lightPurple3};
  border-radius: 15px;
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.purple};
  margin-right: 10px;
  :last-child {
    margin-right: 0;
  }
`;

export default CarCard;
