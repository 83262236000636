import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import styled from 'styled-components';

const Tooltip: FC<TooltipProps> = ({ children, ...outerProps }) => {
  return (
    <StyledReactTooltip
      place="bottom"
      offset={{ top: 20, left: 20, right: 20, bottom: 20 }}
      arrowColor={COLORS.grayDarker2}
      backgroundColor={COLORS.grayDarker2}
      event="click"
      globalEventOff="click"
      overridePosition={({ left, top }, currentEvent, currentTarget, node) => {
        const d = document.documentElement;
        left = Math.min(d.clientWidth - node.clientWidth, left);
        top = Math.min(d.clientHeight - node.clientHeight, top);
        left = Math.max(0, left);
        top = Math.max(0, top);
        return { top, left };
      }}
      {...outerProps}
    >
      {children}
    </StyledReactTooltip>
  );
};

const StyledReactTooltip = styled(ReactTooltip)`
  ${TYPOGRAPHY.body2Light16};
  font-size: 16px !important;
  color: ${COLORS.grayOffWhite} !important;
  background-color: ${COLORS.grayDarker2} !important;
  border-radius: 16px !important;
  max-width: 324px;
  /* width: 100%; */
`;

export default Tooltip;
