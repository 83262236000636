import * as React from 'react';
import { SVGProps } from 'react';

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm.01-11.666a1.11 1.11 0 0 0-1.11 1.11V17a1.11 1.11 0 1 0 2.221 0v-5.554a1.11 1.11 0 0 0-1.11-1.111Zm-.836-2.59a1.11 1.11 0 0 1 1.651-1.487l.011.012a1.11 1.11 0 0 1-1.651 1.487l-.011-.013Z"
    />
  </svg>
);

export default InfoIcon;
