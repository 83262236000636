import React, { FC, useRef, useState } from 'react';
import { ShopProductType } from '@/types';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { InfoIcon } from '@/ui/iconComponents/InfoIcon';
import { ContentCell } from '../ContentCell';
import { Tooltip } from '../../ui/components/Tooltip';
import { UiLink } from '@/ui/components/UiLink';
import { TagSection } from '../TagSection';

interface ShopCardProps {
  sale?: string;
  data: ShopProductType;
  carBatteryCapacity: number;
  carRange: number;
  carPower: number;
}

const ShopCard: FC<ShopCardProps> = ({
  sale,
  data,
  carBatteryCapacity,
  carRange,
  carPower,
}) => {
  const [isShowAdditionalInfo, setIsShowAdditionalInfo] = useState(false);
  const ref = useRef<HTMLElement | null>(null);
  const currentPower =
    carPower > data.ratedMaxPower ? data.ratedMaxPower : carPower;

  const timeNeedetToCharge = carBatteryCapacity / currentPower;
  const chargeSpeed = Math.round(carRange / timeNeedetToCharge);

  const handleRootMouseEnter = () => {
    if (window.innerWidth > 1024) {
      const imageWrap: HTMLElement =
        ref.current.querySelector('.card-image-wrap');
      const additionalInfo: HTMLElement = ref.current.querySelector(
        '.card-additional-info',
      );
      const additionalInfoWrap: HTMLElement = ref.current.querySelector(
        '.card-additional-info-wrap',
      );
      imageWrap.style.maxHeight = '0';

      additionalInfo.style.maxHeight = `${additionalInfoWrap.offsetHeight}px`;

      setIsShowAdditionalInfo(true);
    }
  };

  const handleRootMouseLeave = () => {
    if (window.innerWidth > 1024) {
      const imageWrap: HTMLElement =
        ref.current.querySelector('.card-image-wrap');
      const additionalInfo: HTMLElement = ref.current.querySelector(
        '.card-additional-info',
      );

      imageWrap.style.maxHeight = '';

      additionalInfo.style.maxHeight = '';

      setIsShowAdditionalInfo(false);
    }
  };

  const getValueByKey = (key: string) =>
    data.technicalSpecification.find((item) => item.key === key).value;

  return (
    <Root
      ref={ref}
      onMouseEnter={handleRootMouseEnter}
      onMouseLeave={handleRootMouseLeave}
    >
      <ShopCardLinkToShop href="#" target="_blank" />
      {sale && !isShowAdditionalInfo && <ShopCardSale>-{sale}%</ShopCardSale>}
      <ShopCardWrapper>
        <ShopCardImageWrap className="card-image-wrap">
          {data.images && <img src={data.images[0]} alt={data.name} />}
        </ShopCardImageWrap>

        <ShopCardContent>
          <ShopCardManufactured>{data.manufacture}</ShopCardManufactured>
          <ShopCardTitlePriceWrap>
            <ShopCardName>{data.model}</ShopCardName>
          </ShopCardTitlePriceWrap>
          <ShopCardPrice>
            {sale && <ShopCardOldPrice>450 $</ShopCardOldPrice>}
            {data.price !== 0 && (
              <ShopCardNewPrice>{data.price} $</ShopCardNewPrice>
            )}
          </ShopCardPrice>
          <ShopCardBrand>
            Brand: <span>{data.brandString}</span>
          </ShopCardBrand>
          {data.price === 0 && (
            <ShopCardRequestPrice text="Price upon request" size="small" />
          )}
          <ShopCardDescription>{data.description}</ShopCardDescription>
          <ShopCardMainTechicalInfoList>
            <ContentCell>
              <ShopCardMainTechicalInfoWithTooltip>
                <p>{chargeSpeed} km</p>

                <InfoIcon
                  width={20}
                  height={20}
                  data-tip="Charging speed"
                  fill="#554EF5"
                />
                <Tooltip effect="solid" />
              </ShopCardMainTechicalInfoWithTooltip>
            </ContentCell>
            <ContentCell>
              <p>{getValueByKey('hasApp')}</p>
            </ContentCell>
            <ContentCell>
              <p>{getValueByKey('chargerPower')}</p>
            </ContentCell>
          </ShopCardMainTechicalInfoList>
          <ShopCardaAditionalInfo className="card-additional-info">
            <ShopCardaAditionalInfoWrap className="card-additional-info-wrap">
              <ShopCardaAditionalInfoItem>
                <ShopCardaAditionalInfoText>
                  Connector type
                </ShopCardaAditionalInfoText>
                <ShopCardaAditionalInfoCell>
                  <p>{getValueByKey('connectorType')}</p>
                </ShopCardaAditionalInfoCell>
              </ShopCardaAditionalInfoItem>
              <ShopCardaAditionalInfoItem>
                <ShopCardaAditionalInfoText>
                  Cable length
                </ShopCardaAditionalInfoText>
                <ShopCardaAditionalInfoCell>
                  <p>{getValueByKey('cableLength')}</p>
                </ShopCardaAditionalInfoCell>
              </ShopCardaAditionalInfoItem>
              <ShopCardaAditionalInfoItem>
                <ShopCardaAditionalInfoText>
                  Connection requirments
                </ShopCardaAditionalInfoText>
                <ShopCardaAditionalInfoCell>
                  <p>{getValueByKey('connectionRequirments')}</p>
                </ShopCardaAditionalInfoCell>
              </ShopCardaAditionalInfoItem>
            </ShopCardaAditionalInfoWrap>
          </ShopCardaAditionalInfo>

          {/* <ShopCardAddButton text="Learn more" link="#"></ShopCardAddButton> */}
        </ShopCardContent>
      </ShopCardWrapper>
    </Root>
  );
};

const Root = styled.article`
  position: relative;
  max-width: 392px;
  background-color: ${COLORS.white};
  border: 1px solid ${COLORS.lightGray};
  border-radius: 18px;
  @media (max-width: 600px) {
    max-width: none;
  }
`;

const ShopCardLinkToShop = styled.a`
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  @media (max-width: 1024px) {
    display: block;
  }
`;

const ShopCardSale = styled.div`
  position: absolute;
  top: 40px;
  left: -10px;
  padding: 6px 15px;
  ${TYPOGRAPHY.body2Semibold16};
  color: ${COLORS.white};
  background-color: ${COLORS.purple};
  border-radius: 6px;
`;

const ShopCardWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1024px) {
    padding: 20px;
  }
`;

const ShopCardImageWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 209px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: max-height 0.3s;
  img {
    height: 209px;
    object-fit: cover;
  }
`;

const ShopCardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ShopCardManufactured = styled.p`
  ${TYPOGRAPHY.captionLight14};

  margin-bottom: 4px;
  color: ${COLORS.grayDarker2};
`;

const ShopCardTitlePriceWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const ShopCardName = styled.h2`
  ${TYPOGRAPHY.body3Semibold20}

  color: ${COLORS.black2};
`;

const ShopCardPrice = styled.div`
  display: flex;
  align-items: center;
`;

const ShopCardOldPrice = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.black2};
  margin-bottom: 4px;
  text-decoration: line-through;
`;

const ShopCardNewPrice = styled.p`
  ${TYPOGRAPHY.body3Semibold20};
  color: ${COLORS.black2};
`;

const ShopCardRequestPrice = styled(TagSection)`
  align-self: flex-start;
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: ${COLORS.lightGray2};
`;

const ShopCardBrand = styled.p`
  ${TYPOGRAPHY.captionMedium14}

  margin-bottom: 10px;
  color: ${COLORS.grayDarker2};

  span {
    ${TYPOGRAPHY.body2Medium16}

    color: ${COLORS.black1};
  }
`;

const ShopCardDescription = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${TYPOGRAPHY.captionRegular14}
  margin-bottom: 12px;
  color: ${COLORS.black2};
`;

const ShopCardMainTechicalInfoList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 16px;
`;

const ShopCardMainTechicalInfoWithTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  p {
    margin-right: 10px;
  }
  @media (max-width: 768px) {
    p {
      margin-right: 4px;
    }
  }
`;

const ShopCardaAditionalInfo = styled.div`
  transition: max-height 0.3s;
  overflow: hidden;
  max-height: 0;
  @media (max-width: 1024px) {
    max-height: none;
  }
`;

const ShopCardaAditionalInfoWrap = styled.div`
  padding-bottom: 36px;
  @media (max-width: 1024px) {
    padding-bottom: 0;
  }
`;

const ShopCardaAditionalInfoItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  :last-child {
    margin-bottom: 0;
  }
`;

const ShopCardaAditionalInfoText = styled.p`
  ${TYPOGRAPHY.captionMedium14}

  margin-right: 15px;
  color: ${COLORS.black1};
`;

const ShopCardaAditionalInfoCell = styled(ContentCell)`
  width: 100%;
  max-width: 120px;
`;

const ShopCardAddButton = styled(UiLink)`
  width: 100%;
  margin-top: auto;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export default ShopCard;
