import { Container } from '@/components/Container';
import { COLORS } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { getLocaleUrl } from '@/helpers/getLocaleUrl';
import { CarData } from '@/types';
import { ArrowButton } from '@/ui/components/ArrowButton';
import { navigate } from 'gatsby';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { CarCard } from './components/CarCard';

interface InfoBlockProps {
  carData: CarData;
}

const InfoBlock: FC<InfoBlockProps> = ({ carData }) => {
  const locale = useContext(LocaleContext);
  return (
    <Root>
      <Decor />
      <StyledContainer>
        <BackButton onClick={() => navigate(getLocaleUrl(locale, '/'))} />
        <StyledCarCard data={carData} />
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  width: 100%;
  height: 660px;
  position: relative;
  margin-top: -86px;
  @media (max-width: 1024px) {
    background-color: ${COLORS.mediumPurple};
    height: auto;
    margin-bottom: 100px;
  }
  @media (max-width: 500px) {
    margin-top: -117px;
  }
`;

const StyledContainer = styled(Container)`
  padding-top: 140px;
  padding-bottom: 60px;
  @media (max-width: 1024px) {
    padding-top: 116px;
    padding-bottom: 25px;
  }
`;

const Decor = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 440px;
  background: url('/images/infoblock-car.svg') no-repeat center;
  background-size: contain;
  background-color: ${COLORS.mediumPurple};
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledCarCard = styled(CarCard)`
  max-width: 720px;
  margin: 0 auto;
`;

const BackButton = styled(ArrowButton)`
  display: none;
  transform: rotate(180deg);
  margin-bottom: 32px;
  @media (max-width: 1024px) {
    display: block;
  }
`;

export default InfoBlock;
