import { CustomLink } from '@/components/CustomLink';
import { BUTTONS_THEME } from '@/constants';
import { ButtonThemes } from '@/types';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface UiLinkProps {
  text: string;
  link: string;
  className?: string;
  customTheme?: ButtonThemes;
}

const UiLink: FC<UiLinkProps> = ({
  text,
  link,
  customTheme = 'primary',
  className,
}) => {
  return (
    <Root className={className} to={link} $theme={customTheme}>
      {text}
    </Root>
  );
};

const Root = styled(CustomLink)<{ $theme: string }>`
  ${({ $theme }) => BUTTONS_THEME[$theme]}
`;

export default UiLink;
