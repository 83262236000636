import React from 'react';
import { navigate } from 'gatsby';
import { InfoBlock } from '@/pageComponents/CarInfoPage/InfoBlock';
import { Seo } from '@/components/Seo';
import { CarData } from '@/types';
import { MainHeading } from '@/components/MainHeading';
import { ChargersBlock } from '@/pageComponents/CarInfoPage/ChargersBlock';
import CarInfoLayout from '@/layouts/CarInfoLayout';

const CarInfo = ({ location, pageContext, ...props }) => {
  const isBrowser = typeof window !== 'undefined';

  if (!location.state) {
    {
      isBrowser && navigate('/');
    }
    return null;
  } else {
    const carData: CarData | undefined = location.state?.carData;
    if (carData) {
      const fullCarName = `${carData.Vehicle_Make} ${carData.Vehicle_Model} ${
        carData?.Vehicle_Model_Version ? carData?.Vehicle_Model_Version : ''
      }`;
      const seo = {
        keywords: `${carData.Vehicle_Make}, ${carData.Vehicle_Model}, ${carData?.Vehicle_Model_Version}`,
        metaDescription: `Information for ${fullCarName}`,
        metaTitle: fullCarName,
        mainHeading: fullCarName,
        sharedImage: {
          url: carData.Images[0],
        },
      };
      return (
        <CarInfoLayout isTransparentHeader={true} locale={pageContext.locale}>
          <Seo data={seo} />
          <MainHeading text={fullCarName} />

          <InfoBlock carData={carData} />
          <ChargersBlock carData={carData} />
        </CarInfoLayout>
      );
    }
  }
};

export default CarInfo;
