import { Container } from '@/components/Container';
import { ShopCard } from '@/components/ShopCard';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { getArrayWithUniquiElems } from '@/helpers/getArrayWithUniquiElems';
import { getKeyByValue } from '@/helpers/getKeyByValue';
import { useLocaleData } from '@/hooks/useLocaleData';
import { CarData, ShopProductType } from '@/types';
import Checkbox from '@/ui/components/Checkbox';
import { StyledRange } from '@/ui/components/StyledRange';
import React, {
  FC,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { NeededHourses } from '../NeededHourses';
import { getChargerItems } from './api/getChargerItems';

interface ChargersBlockProps {
  carData: CarData;
}

enum TypesCharger {
  AC = 'AC',
  DC = 'DC',
}

const marks = {
  0: '3.4 kW',
  14: '7.4 kW',
  28: '11 kW',
  42: '22 kW',
  56: '40 kW',
  71: '100 kW',
  85: '160 kW',
  100: '>160 kW',
};
const powers = getArrayWithUniquiElems(
  Object.values(marks).map((item) =>
    Number(item.replace(/[^\0-9\.]+/g, '').trim()),
  ),
);

const findClosestValue = (number) => {
  if (powers.includes(number)) {
    return number;
  }
  return number < 160
    ? Math.min(...powers.filter((value) => value > number))
    : '>160';
};

const getValueRange = (value: string, min: number, max: number) => {
  return value === TypesCharger.AC
    ? [0, Number(getKeyByValue(marks, min))]
    : [56, Number(getKeyByValue(marks, max))];
};

const getValuesBetweenKeys = (values: number[]) => {
  const marksKeys = Object.keys(marks).sort(function (a: any, b: any) {
    return a - b;
  });
  const lowBorderIndex = marksKeys.indexOf(values[0].toString());
  const highBorderIndex = marksKeys.indexOf(values[1].toString());

  const currentKeys = marksKeys.filter(
    (item, index) => index >= lowBorderIndex && index <= highBorderIndex,
  );

  const currentValues = currentKeys.map((item) =>
    marks[item].replace(/[^\0-9\.]+/g, '').trim(),
  );

  const resultValues = getArrayWithUniquiElems(currentValues).map((item) => ({
    label: `${item} kW`,
    power: Number(item),
  }));

  return resultValues;
};

const ChargersBlock: FC<ChargersBlockProps> = ({ carData }) => {
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, 'chargersFilter'),
    [locale],
  );
  const rangeRef = useRef<any | null>(null);
  const [chargers, setChargers] = useState<ShopProductType[]>([]);
  const [typeChargerValue, setTypeChargerValue] = useState('AC');
  const closestRightAC = findClosestValue(carData.Charge_Standard_Power);
  const closestRightDC = findClosestValue(carData.Fastcharge_Power_Max);

  const valueRange = getValueRange(
    typeChargerValue,
    closestRightAC,
    closestRightDC,
  );
  const [neededHours, setNeededHours] = useState(
    getValuesBetweenKeys(valueRange),
  );

  const handleChangeRadioButtonGroup = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChargers([]);
    setTypeChargerValue(e.target.value);

    const currentValueRange = getValueRange(
      e.target.value,
      closestRightAC,
      closestRightDC,
    );

    rangeRef.current.state.bounds = currentValueRange;
  };

  const fetchChargerItems = async (
    minPowerValue,
    maxPowerValue,
    typeChargerValue,
  ) => {
    const step = typeChargerValue === TypesCharger.AC ? 4 : 30;
    const data = await getChargerItems(
      minPowerValue,
      maxPowerValue,
      typeChargerValue,
    );
    const {
      items_GetMany: { items },
    } = data;

    if (items.length === 0) {
      fetchChargerItems(minPowerValue, maxPowerValue + step, typeChargerValue);
      const rangePowers = [
        Number(getKeyByValue(marks, findClosestValue(minPowerValue))),
        Number(getKeyByValue(marks, findClosestValue(maxPowerValue + step))),
      ];
      rangeRef.current.state.bounds = rangePowers;

      setNeededHours(getValuesBetweenKeys(rangePowers));

      return;
    }

    setChargers(items);
  };

  useEffect(() => {
    const floatDCPower =
      closestRightDC === '>160' ? carData.Fastcharge_Power_Max : closestRightDC;

    const maxPowerValue =
      typeChargerValue === TypesCharger.AC
        ? parseFloat(carData.Charge_Standard_Power.toFixed(0))
        : parseFloat(floatDCPower.toFixed(0));
    const minPowerValue = typeChargerValue === TypesCharger.AC ? 3 : 40;

    setNeededHours(getValuesBetweenKeys(valueRange));
    fetchChargerItems(minPowerValue, maxPowerValue, typeChargerValue);
  }, [typeChargerValue]);

  return (
    <section>
      <StyledContainer>
        <Title>{dataLocale.title}</Title>
        <Filters>
          <StyledRange
            ref={rangeRef}
            dots={false}
            defaultValue={valueRange}
            marks={marks}
            disabled
            step={null}
          />
          <StyledNeededHourses
            powers={neededHours}
            carBatteryCapacity={carData.Battery_Capacity_Useable}
          />
          {carData.Fastcharge_ChargeSpeed && (
            <TypeChargerWrap>
              <TypeChargerTitle>{dataLocale.typeChargerTitle}</TypeChargerTitle>
              <RadioButtonsWrapper onChange={handleChangeRadioButtonGroup}>
                <StyledCheckbox
                  className={
                    typeChargerValue === TypesCharger.AC ? 'active' : ''
                  }
                  checked={typeChargerValue === TypesCharger.AC}
                  value="AC"
                  type="radio"
                  readOnly
                />
                <StyledCheckbox
                  className={
                    typeChargerValue === TypesCharger.DC ? 'active' : ''
                  }
                  checked={typeChargerValue === TypesCharger.DC}
                  value="DC"
                  type="radio"
                  readOnly
                />
              </RadioButtonsWrapper>
            </TypeChargerWrap>
          )}
        </Filters>
        <ChargerList>
          {chargers.map((item) => (
            <ShopCard
              data={item}
              carBatteryCapacity={carData.Battery_Capacity_Useable}
              carRange={carData.Range_Real}
              carPower={
                typeChargerValue === TypesCharger.AC
                  ? carData.Charge_Standard_Power
                  : carData.Fastcharge_Power_Max
              }
              key={item.id}
            />
          ))}
        </ChargerList>
      </StyledContainer>
    </section>
  );
};

const StyledContainer = styled(Container)`
  padding-bottom: 100px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title3SemiBold32};
  color: ${COLORS.black2};
  text-align: center;
  margin-bottom: 40px;
  @media (max-width: 600px) {
    ${TYPOGRAPHY.headlineSemiBold22};
    margin-bottom: 32px;
  }
`;

const Filters = styled.div`
  margin-bottom: 60px;
  @media (max-width: 700px) {
    margin-bottom: 32px;
  }
`;

const TypeChargerWrap = styled.div`
  margin-top: 44px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 700px) {
    margin-top: 32px;
  }
`;

const TypeChargerTitle = styled.p`
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.grayDarker2};
  margin-bottom: 24px;
  text-align: center;
  @media (max-width: 700px) {
    ${TYPOGRAPHY.body2Medium16};
    margin-bottom: 20px;
  }
`;

const RadioButtonsWrapper = styled.div`
  display: flex;
  @media (max-width: 700px) {
    width: 100%;
    justify-content: center;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  background-color: ${COLORS.lightGray3};
  min-width: 288px;
  min-height: 80px;
  display: flex;
  border-radius: 16px;
  border: 2px solid transparent;
  margin-right: 24px;
  :last-child {
    margin-right: 0;
  }
  &.active {
    border-color: ${COLORS.purple};
  }
  label {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 78px;
    ::before {
      left: 38px;
    }
  }
  @media (max-width: 700px) {
    min-width: auto;
    min-height: 60px;
    width: 100%;
    max-width: 160px;
    margin-right: 16px;
    label {
      padding-left: 70px;
      ::before {
        left: 20px;
      }
    }
  }
`;

const ChargerList = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    gap: 32px;
  }
`;

const StyledNeededHourses = styled(NeededHourses)`
  margin-top: 40px;
`;

export default ChargersBlock;
