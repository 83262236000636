import { Range, RangeProps } from 'rc-slider';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import 'rc-slider/assets/index.css';

interface StyledRangeProps extends RangeProps {}

const StyledRange = forwardRef<any, StyledRangeProps>(({ ...props }, ref) => {
  return (
    <Root>
      <Range {...props} ref={ref} />
    </Root>
  );
});

const Root = styled.div`
  padding-bottom: 48px;

  .rc-slider {
    padding: 0;
  }
  .rc-slider-step {
    display: none;
  }
  .rc-slider-mark {
    top: auto;
    bottom: -14px;
  }
  .rc-slider,
  .rc-slider-rail,
  .rc-slider-track {
    height: 16px;
  }
  .rc-slider-rail {
    background-color: ${COLORS.lightGray8};

    border-radius: 20px;
  }
  .rc-slider-track {
    background-color: ${COLORS.lightPurple};

    border-radius: 20px;
  }
  .rc-slider-handle {
    width: 20px;
    height: 20px;
    border-color: ${COLORS.purple};
    background-color: ${COLORS.purple};
    margin-top: -2px;
    :hover {
      border-color: ${COLORS.purple};
    }
    :active,
    &.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
      border-color: ${COLORS.purple};
      box-shadow: 0 0 5px ${COLORS.purple};
    }
    :focus,
    &.rc-slider-handle-click-focused {
      border-color: ${COLORS.purple};
    }
  }
  .rc-slider-mark-text {
    ${TYPOGRAPHY.body1Medium18};
    color: ${COLORS.black1};
    min-width: 75px;
    pointer-events: none;
  }
  .rc-slider-mark-text-active {
    color: ${COLORS.purple};
  }
  .rc-slider-disabled .rc-slider-handle {
    border-color: ${COLORS.purple};
    background-color: ${COLORS.purple};
    opacity: 0;
    cursor: auto;
  }
  .rc-slider-disabled .rc-slider-track {
    background-color: ${COLORS.purple};
  }

  @media (max-width: 1024px) {
    padding-top: 48px;
    .rc-slider-mark {
      top: 0;
      bottom: 0;
      width: auto;
      left: 32px;
      right: 32px;
    }
    .rc-slider-mark-text {
      ${TYPOGRAPHY.captionRegular14};
      text-align: left;
      :nth-child(odd) {
        bottom: -44px;
      }
      :nth-child(even) {
        top: -44px;
      }
    }
  }
`;
export default StyledRange;
