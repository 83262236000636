import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC, InputHTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';

const Checkbox: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  value,
  disabled = false,
  checked,
  type = 'checkbox',
  className,
  ...outerProps
}) => {
  const id = useMemo(() => uuid(), []);

  return (
    <Root className={className}>
      <InputCheckbox
        className="visually-hidden"
        disabled={disabled}
        checked={checked}
        id={id}
        type={type}
        value={value}
        {...outerProps}
      />
      <Label htmlFor={id}>{value}</Label>
    </Root>
  );
};

const Root = styled.p`
  position: relative;
`;

const InputCheckbox = styled.input`
  :hover:not(input[type='checkbox']:disabled) + label {
    &::before {
      background: ${COLORS.gray};
    }
    :focus:not(input[type='checkbox']:disabled) + label {
      &::before {
        background: ${COLORS.lightGray8};
        box-shadow: 0 0 0 8px ${COLORS.lightGray2};
      }
    }
  }
  :checked {
    & + label {
      &::before {
        background-color: ${COLORS.purple};
        content: url('/images/checkbox-checked-icon.svg');
      }
    }
    :hover:not(input[type='checkbox']:disabled) + label {
      &::before {
        background: ${COLORS.darkPurple2};
      }
    }
    :focus:not(input[type='checkbox' i]:disabled) + label {
      &::before {
        background: ${COLORS.purple};
        box-shadow: 0 0 0 8px ${COLORS.lightPurple};
      }
    }
    :disabled {
      & + label {
        ::before {
          opacity: 0.8;
        }
      }
    }
  }
  :disabled {
    & + label {
      ::before {
        opacity: 0.8;
      }
    }
  }
`;

const Label = styled.label`
  padding-left: 38px;
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black1};
  position: relative;
  cursor: pointer;
  ::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    background: ${COLORS.lightGray8};
    border-radius: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease-out;
  }
`;

export default Checkbox;
