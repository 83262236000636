import { API_URLS } from '@/constants';

export const getChargerItems = async (
  minPower: number,
  maxPower: number,
  chargingCategory: string,
) => {
  const endpoint = API_URLS.appBackendUrl;
  const headers = {
    'content-type': 'application/json',
  };
  const graphqlQuery = {
    operationName: 'GetManyItems',
    query: `query GetManyItems($power: MaxMinInput!, $chargingCategory: ChargingCategory!) {
      items_GetMany(
        filter: {itemType: CHARGER, ratedMaxPower: $power, chargingCategory: $chargingCategory}
        pagination: { page: 1, perPage: 10 }
        sorting: { sortBy: [{ field: PRICE, direction: DESC }] }
      ) {
        items {
          id
          name
          description
          slug
          price
          images
          __typename
          ... on ChargerItem {
            model
            brandString
            manufacture
            ratedMaxPower
            technicalSpecification {
              key
              value
            }
            chargingCategory
            
          }
        }
        page
        perPage
        total
      }
    }
    
    `,
    variables: {
      power: {
        min: minPower,
        max: maxPower,
      },
      chargingCategory: chargingCategory,
    },
  };

  const options = {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(graphqlQuery),
  };

  const request = await fetch(endpoint, options);
  const { data } = await request.json();

  return data;
};
