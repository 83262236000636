import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { TransparentHeader } from '@/components/Header/components/TransparentHeader';
import { LocaleContext } from '@/contexts/LocaleContext';
import '@/libs/localizations';
import dayjs from 'dayjs';
import { ClientContext, GraphQLClient } from 'graphql-hooks';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { CookiesProvider } from 'react-cookie';
import { API_URLS } from '@/constants';
import { WhatsappLink } from '@/components/WhatsappLink';

interface CarInfoLayoutProps {
  isTransparentHeader?: boolean;
  locale?: string;
}

const config = new GraphQLClient({
  url: API_URLS.apiStrapiUrl,
});

const CarInfoLayout: FC<CarInfoLayoutProps> = ({
  isTransparentHeader = false,
  locale = 'en',
  children,
}) => {
  useEffect(() => {
    dayjs.locale(locale);
  }, [locale]);

  return (
    <CookiesProvider>
      <ClientContext.Provider value={config}>
        <LocaleContext.Provider value={locale}>
          <Root>
            {isTransparentHeader ? <TransparentHeader /> : <Header />}
            <WhatsappLink />
            {children}
            <Footer withoutLangSwitcher={true} />
          </Root>
        </LocaleContext.Provider>
      </ClientContext.Provider>
    </CookiesProvider>
  );
};

const Root = styled.div`
  position: relative;
`;

export default CarInfoLayout;
