import { COLORS, TYPOGRAPHY } from '@/constants';
import { LocaleContext } from '@/contexts/LocaleContext';
import { useLocaleData } from '@/hooks/useLocaleData';
import React, { FC, useContext, useMemo } from 'react';
import styled from 'styled-components';

interface PowerData {
  label: string;
  power: number;
}

interface NeededHoursesProps {
  className?: string;
  powers: PowerData[];
  carBatteryCapacity: number;
}

const NeededHourses: FC<NeededHoursesProps> = ({
  className,
  powers,
  carBatteryCapacity,
}) => {
  const locale = useContext(LocaleContext);
  const dataLocale = useMemo(
    () => useLocaleData(locale, 'neededHoursesBlock'),
    [locale],
  );

  return (
    <Root className={className}>
      <Title>{dataLocale.title}</Title>
      <Subtitle>{dataLocale.subtitle}</Subtitle>
      <ChargerTimes>
        {powers.map((item, index) => {
          const neededHourses = carBatteryCapacity / item.power;
          return (
            <ChargerTime key={index}>
              {item.label} — {`${parseFloat(neededHourses.toFixed(1))}H`}
            </ChargerTime>
          );
        })}
      </ChargerTimes>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h3`
  ${TYPOGRAPHY.headlineMedium22};
  color: ${COLORS.black2};
  margin-bottom: 12px;
  text-align: center;
`;

const Subtitle = styled.p`
  ${TYPOGRAPHY.body2Regular16};
  color: ${COLORS.grayDarker2};
  margin-bottom: 24px;
  max-width: 515px;
  text-align: center;
`;

const ChargerTimes = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  gap: 32px 24px;
  width: 100%;
  max-width: 872px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
  }
`;

const ChargerTime = styled.li`
  ${TYPOGRAPHY.body1SemiBold18};
  color: ${COLORS.black1};
  padding: 12px 24px;
  border-radius: 18px;
  border: 1px solid ${COLORS.lightGray};
  text-align: center;
  @media (max-width: 600px) {
    ${TYPOGRAPHY.captionRegular14};
  }
  @media (max-width: 374px) {
    padding: 12px;
  }
`;

export default NeededHourses;
